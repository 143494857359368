<template>
  <CRow class="schedule-detail">
    <CCol col="12">
      <CCard class="zq--wizard-card">
        <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
          <div class="d-flex justify-content-between">
            <strong class="title">{{ texts.createPage.summary.scheduling.title }}</strong>
            <CLink class="card-header-action btn-minimize">
              <ClCardArrow :is-collapsed="basicIsCollapsed" />
            </CLink>
          </div>
        </CCardHeader>
        <CCollapse :show="basicIsCollapsed" :duration="400">
          <CCardBody>
            <ul v-if="data && data.length">
              <li v-for="(item, index) in data" :key="index">
                <span class="overview-label">On Award Status Change To:</span>&nbsp;
                <span>{{ item.onStatusChangeTo }}</span>
                <br/>
                <span class="overview-label">Active From:</span>&nbsp;
                <span>{{ item.thenAwardActiveFrom }}</span>
                <br/>
                <span class="overview-label">Active Until:</span>&nbsp;
                <span>{{ item.thenAwardActiveUntil }}</span>
                <hr style="border-color: #ebebeb"/>
              </li>
            </ul>
          </CCardBody>
        </CCollapse>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import ClCardRow from '@/shared/UI/ClCardRow';
import ClCardArrow from '@/shared/UI/ClCardArrow';
import {dateFormate} from '@/utils/dateFormate';
import {achievementsTexts} from "@/config/pageTexts/achievements.json";
import {cloneDeep} from "lodash";

export default {
  name: 'ViewAwardScheduling',
  components: {
    ClCardRow,
    ClCardArrow
  },
  props: {
    schedulingData: Array
  },
  data() {
    return {
      texts: {
        ...achievementsTexts
      },
      basicIsCollapsed: false,
      data: []
    }
  },
  created() {
    this.data = cloneDeep(this.schedulingData);
  },
  methods: {
    dateFormate(val) {
      return val ? dateFormate(val) : ''
    },
  },
  watch: {
    schedulingData: {
      deep: true,
      handler(val) {
        this.data = val;
      }
    }
  }
}
</script>

<style lang="scss">
.schedule-detail {
  .every-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;

    li {
      background: #f1f1f1 none repeat scroll 0 0;
      border: 1px solid #f1f1f1;
      cursor: pointer;
      float: left;
      font-size: 16px;
      line-height: 30px;
      margin: 0 2px 2px 0;
      min-width: 45px;
      padding: 0 3px;
      text-align: center;
    }
  }

  @media screen and (max-width: 576px) {
    .overview-content {
      text-align: center;
    }
  }
}
</style>
